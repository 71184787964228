"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var clone = require("just-clone");
var store_1 = require("@ngrx/store");
var array_1 = require("@utils/array");
var app_reducer_1 = require("../../../app.reducer");
var TagsComponent = /** @class */ (function () {
    function TagsComponent(store) {
        this.store = store;
        this.tags = [];
        this.attach = new core_1.EventEmitter();
        this.detach = new core_1.EventEmitter();
        this.updating = null;
        //
    }
    TagsComponent.prototype.toggle = function (tag) {
        if (this.attached(tag)) {
            this.detach.emit(tag);
        }
        else {
            this.attach.emit(tag);
        }
    };
    TagsComponent.prototype.clone = function (item) {
        return clone(item);
    };
    TagsComponent.prototype.updated = function (payload) {
        this.store.dispatch(new app_reducer_1.SignedInTagsUpdated(payload));
        this.updating = null;
    };
    TagsComponent.prototype.attached = function (tag) {
        return array_1.notEmpty(array_1.where(this.project.tags, { id: tag.id }));
    };
    return TagsComponent;
}());
exports.TagsComponent = TagsComponent;
