"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var filters_reducer_1 = require("../filters.reducer");
var FiltersComponent = /** @class */ (function () {
    function FiltersComponent(router, store, route) {
        this.router = router;
        this.store = store;
        this.route = route;
        this.active = 0;
        this.selected = new core_1.EventEmitter();
        this.filters$ = this.store.pipe(rx_1.choose(function (x) { return x.filters; }));
        //
    }
    Object.defineProperty(FiltersComponent.prototype, "workflow", {
        set: function (workflow) {
            this.store.dispatch(new filters_reducer_1.FiltersLoad({ workflow: workflow }));
        },
        enumerable: true,
        configurable: true
    });
    FiltersComponent.prototype.select = function (filter) {
        this.selected.emit(filter);
    };
    FiltersComponent.prototype.edit = function (filter) {
        this.router.navigate(['dashboard', 'filters', filter.id, 'edit'], {
            queryParams: this.route.snapshot.queryParams,
        });
    };
    return FiltersComponent;
}());
exports.FiltersComponent = FiltersComponent;
