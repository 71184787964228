"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Translate_pipe_1 = require("./Translate.pipe");
var Markdown_pipe_1 = require("./Markdown.pipe");
var Date_pipe_1 = require("./Date.pipe");
var imports = [Translate_pipe_1.TranslatePipe, Markdown_pipe_1.MarkdownPipe, Date_pipe_1.DatePipe];
var PipesModule = /** @class */ (function () {
    function PipesModule() {
    }
    return PipesModule;
}());
exports.PipesModule = PipesModule;
