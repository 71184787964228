"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function first(input) {
    return input[0];
}
exports.first = first;
function firstOr(input, _default) {
    return input[0] || _default;
}
exports.firstOr = firstOr;
function firstOrFail(input) {
    return (input[0] ||
        (function () {
            throw new Error('Requested "first" item from an empty array.');
        })());
}
exports.firstOrFail = firstOrFail;
function notEmpty(input) {
    return input.length > 0;
}
exports.notEmpty = notEmpty;
function where(input, conditions) {
    return Object.entries(conditions)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return function (item) { return item[key] == value; };
    })
        .reduce(function (items, fn) { return items.filter(fn); }, input);
}
exports.where = where;
function not(input, conditions) {
    return Object.entries(conditions)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return function (item) { return item[key] == value; };
    })
        .reduce(function (items, fn) { return items.filter(function (x) { return !fn(x); }); }, input);
}
exports.not = not;
