"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var general_1 = require("@utils/general");
var router_1 = require("@angular/router");
var Response_1 = require("@utils/Response");
var KEYS = ['page', 'phrase', 'sort_by', 'sort_direction'];
var PaginatedViewComponent = /** @class */ (function () {
    function PaginatedViewComponent(router, route) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.response = Response_1.empty();
        this.load = new core_1.EventEmitter();
        this.innerState = {
            phrase: '',
        };
        /**
         * View helpers.
         */
        this.sortBy = function (field) {
            general_1.tap(_this.response.sorting, function (sort) {
                if (sort.by == field || sort.by == '') {
                    _this.update({ sort_direction: sort.direction == 'asc' ? 'desc' : 'asc' });
                }
                else {
                    _this.update({ sort_by: field, sort_direction: 'asc' });
                }
            });
        };
        this.setPage = function (page) {
            _this.update({ page: page });
        };
        this.setPhrase = function (phrase) {
            _this.update({ page: 1, phrase: phrase });
        };
        this.setAdditional = function (params) {
            _this.update(params);
        };
        this.hash = hash();
    }
    Object.defineProperty(PaginatedViewComponent.prototype, "refresh", {
        set: function (x) {
            if (!x)
                return;
            this.update(x);
        },
        enumerable: true,
        configurable: true
    });
    PaginatedViewComponent.prototype.ngOnInit = function () {
        this.update({});
    };
    Object.defineProperty(PaginatedViewComponent.prototype, "pagination", {
        get: function () {
            return __assign({}, this.response.pagination, { id: this.hash });
        },
        enumerable: true,
        configurable: true
    });
    PaginatedViewComponent.prototype.update = function (params) {
        var _this = this;
        var xs = merge(this.route.snapshot.queryParams, params);
        this.router
            .navigate([], {
            relativeTo: this.route,
            queryParams: xs,
        })
            .then(function () {
            _this.innerState = xs;
            _this.load.emit(general_1.drop(xs, function (x) { return !KEYS.includes(x); }));
        });
    };
    return PaginatedViewComponent;
}());
exports.PaginatedViewComponent = PaginatedViewComponent;
function merge(left, right) {
    return __assign({}, general_1.drop(left, function (k, v) { return Object.keys(right).includes(k) || !v; }), general_1.dropFalsy(right));
}
function hash(length) {
    if (length === void 0) { length = 8; }
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}
