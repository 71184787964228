"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var NewVersionAvailableComponent = /** @class */ (function () {
    function NewVersionAvailableComponent(router) {
        this.router = router;
        //
    }
    NewVersionAvailableComponent.prototype.reload = function () {
        localStorage.removeItem('app-release');
        this.router
            .navigate(['', { outlets: { secondary: null } }])
            .then(function () { return window.location.reload(); });
    };
    return NewVersionAvailableComponent;
}());
exports.NewVersionAvailableComponent = NewVersionAvailableComponent;
