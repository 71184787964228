"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var rx_1 = require("@utils/rx");
var url_1 = require("@utils/url");
var UsersDetailComponent = /** @class */ (function () {
    function UsersDetailComponent(router, route, store) {
        this.router = router;
        this.route = route;
        this.store = store;
        this.user$ = this.store.pipe(rx_1.choose(function (x) { return x.dashboard.data; }), rx_1.flatten(), rx_1.flatMap(function (x) { return x.team.users; }), rx_1.where({ id: this.route.snapshot.params.id }), operators_1.take(1));
        //
    }
    UsersDetailComponent.prototype.close = function () {
        this.router.navigate(['../..'], { relativeTo: this.route, queryParamsHandling: 'merge' });
    };
    UsersDetailComponent.prototype.statistics = function (user) {
        var url = url_1.make("/dashboard/user-detail/" + user.id + "/projects", {
            team: { assignee: [user.id] },
        });
        this.router.navigateByUrl(url);
    };
    return UsersDetailComponent;
}());
exports.UsersDetailComponent = UsersDetailComponent;
