"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MarkdownPipe = /** @class */ (function () {
    function MarkdownPipe() {
    }
    MarkdownPipe.prototype.transform = function (input) {
        return (input || '').replace(/\[(.*?)\]\((.*?)\)/g, "<a href=\"$2\" class=\"text-blue-500 no-underline\">$1</a>");
    };
    return MarkdownPipe;
}());
exports.MarkdownPipe = MarkdownPipe;
