"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var operators_1 = require("rxjs/operators");
var TeamComponent = /** @class */ (function () {
    function TeamComponent() {
    }
    return TeamComponent;
}());
exports.TeamComponent = TeamComponent;
var TeamInnerComponent = /** @class */ (function () {
    function TeamInnerComponent(store) {
        var _this = this;
        this.store = store;
        this._source = {};
        this.roles$ = this.store.pipe(rx_1.choose(function (x) { return x.roles; }));
        this.model = {
            user: null,
            get user_id() {
                return this._value;
            },
            set user_id(id) {
                this._value = id;
                this._callback(this._value);
            },
            _value: null,
            _callback: function (arg) { return _this.callback(arg); },
        };
        //
    }
    Object.defineProperty(TeamInnerComponent.prototype, "source", {
        set: function (source) {
            this._source = source;
            this.run();
        },
        enumerable: true,
        configurable: true
    });
    TeamInnerComponent.prototype.run = function () {
        var _this = this;
        if (this.real) {
            this.model.user = this.real;
            this.model.user_id = this.real.id;
        }
        this.roles$.pipe(operators_1.first()).subscribe(function (roles) {
            if (roles.filter(function (x) { return x.key === _this.role; }).length == 0) {
                throw new Error("Role [" + _this.role + "] does not exist.");
            }
        });
        this.role$ = this.roles$.pipe(rx_1.flatten(), operators_1.filter(function (x) { return x.key == _this.role; }), operators_1.first());
    };
    TeamInnerComponent.prototype.callback = function (id) {
        if (this.real && this.real.id === id) {
            return;
        }
        this._source[this.role] =
            id !== null ? { user_id: id, role: this.role } : null;
    };
    Object.defineProperty(TeamInnerComponent.prototype, "real", {
        get: function () {
            var member = this._source[this.role];
            if (member) {
                return member.user;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return TeamInnerComponent;
}());
exports.TeamInnerComponent = TeamInnerComponent;
