"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SearchBoxComponent = /** @class */ (function () {
    function SearchBoxComponent() {
        this.phrase = '';
        this.placeholder = '';
        this.filter = new core_1.EventEmitter();
    }
    return SearchBoxComponent;
}());
exports.SearchBoxComponent = SearchBoxComponent;
