"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var date_fns_1 = require("date-fns");
var DatePipe = /** @class */ (function () {
    function DatePipe() {
    }
    DatePipe.prototype.transform = function (input, style) {
        if (style === void 0) { style = 'yyyy-MM-dd'; }
        if (!input) {
            return '---';
        }
        if (style == 'short-date') {
            return date_fns_1.format(input instanceof Date ? input : date_fns_1.parseISO(input), "d LLL ''yy");
        }
        if (style == 'short-date-with-time') {
            return date_fns_1.format(input instanceof Date ? input : date_fns_1.parseISO(input), "d LLL ''yy HH:mm");
        }
        return date_fns_1.format(input instanceof Date ? input : date_fns_1.parseISO(input), style);
    };
    return DatePipe;
}());
exports.DatePipe = DatePipe;
