"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Response_1 = require("@utils/Response");
var array_1 = require("@utils/array");
var SuppliersLoad = /** @class */ (function () {
    function SuppliersLoad(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_LOAD';
        //
    }
    return SuppliersLoad;
}());
exports.SuppliersLoad = SuppliersLoad;
var SuppliersLoaded = /** @class */ (function () {
    function SuppliersLoaded(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_LOADED';
        //
    }
    return SuppliersLoaded;
}());
exports.SuppliersLoaded = SuppliersLoaded;
var SuppliersCreated = /** @class */ (function () {
    function SuppliersCreated(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_CREATED';
        //
    }
    return SuppliersCreated;
}());
exports.SuppliersCreated = SuppliersCreated;
var SuppliersCreatedFromNewSupplier = /** @class */ (function () {
    function SuppliersCreatedFromNewSupplier(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_CREATED_FROM_NEW_SUPPLIER';
        //
    }
    return SuppliersCreatedFromNewSupplier;
}());
exports.SuppliersCreatedFromNewSupplier = SuppliersCreatedFromNewSupplier;
var SuppliersUpdated = /** @class */ (function () {
    function SuppliersUpdated(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_UPDATED';
        //
    }
    return SuppliersUpdated;
}());
exports.SuppliersUpdated = SuppliersUpdated;
var SuppliersRemove = /** @class */ (function () {
    function SuppliersRemove(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_REMOVE';
        //
    }
    return SuppliersRemove;
}());
exports.SuppliersRemove = SuppliersRemove;
var SuppliersRemoved = /** @class */ (function () {
    function SuppliersRemoved(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_REMOVED';
        //
    }
    return SuppliersRemoved;
}());
exports.SuppliersRemoved = SuppliersRemoved;
var SuppliersContactCreated = /** @class */ (function () {
    function SuppliersContactCreated(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_CONTACT_CREATED';
        //
    }
    return SuppliersContactCreated;
}());
exports.SuppliersContactCreated = SuppliersContactCreated;
var SuppliersContactRemove = /** @class */ (function () {
    function SuppliersContactRemove(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_CONTACT_REMOVE';
        //
    }
    return SuppliersContactRemove;
}());
exports.SuppliersContactRemove = SuppliersContactRemove;
var SuppliersContactRemoved = /** @class */ (function () {
    function SuppliersContactRemoved(payload) {
        this.payload = payload;
        this.type = 'SUPPLIERS_CONTACT_REMOVED';
        //
    }
    return SuppliersContactRemoved;
}());
exports.SuppliersContactRemoved = SuppliersContactRemoved;
function suppliers(state, action) {
    if (state === void 0) { state = Response_1.empty(); }
    switch (action.type) {
        case 'SUPPLIERS_LOADED':
            return action.payload;
        case 'SUPPLIERS_CREATED':
        case 'SUPPLIERS_CREATED_FROM_NEW_SUPPLIER':
            return __assign({}, state, { data: state.data.concat([action.payload]) });
        case 'SUPPLIERS_UPDATED':
            return __assign({}, state, { data: array_1.not(state.data, { id: action.payload.id }).concat([action.payload]) });
        case 'SUPPLIERS_REMOVED':
            return __assign({}, state, { data: array_1.not(state.data, { id: action.payload.id }) });
        case 'SUPPLIERS_CONTACT_CREATED':
            return __assign({}, state, { data: array_1.not(state.data, { id: action.payload.supplier_id }).concat([
                    __assign({}, array_1.firstOrFail(array_1.where(state.data, { id: action.payload.supplier_id })), { contacts: array_1.firstOrFail(array_1.where(state.data, { id: action.payload.supplier_id })).contacts.concat([action.payload]) }),
                ]) });
        case 'SUPPLIERS_CONTACT_REMOVED':
            return __assign({}, state, { data: array_1.not(state.data, { id: action.payload.supplier_id }).concat([
                    __assign({}, array_1.firstOrFail(array_1.where(state.data, { id: action.payload.supplier_id })), { contacts: array_1.not(array_1.firstOrFail(array_1.where(state.data, { id: action.payload.supplier_id }))
                            .contacts, { id: action.payload.id }) }),
                ]) });
        default:
            return state;
    }
}
exports.suppliers = suppliers;
