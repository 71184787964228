"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var justClone = require("just-clone");
var store_1 = require("@ngrx/store");
var effects_1 = require("@ngrx/effects");
function ofType() {
    var types = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        types[_i] = arguments[_i];
    }
    return effects_1.ofType.apply(void 0, types);
}
exports.ofType = ofType;
function choose(mapFn) {
    return function (source) {
        return source.pipe(store_1.select(mapFn), operators_1.filter(function (input) {
            return input !== null && input !== undefined;
        }));
    };
}
exports.choose = choose;
function once(mapFn) {
    return function (source) {
        return source.pipe(choose(mapFn), operators_1.take(1));
    };
}
exports.once = once;
function clone() {
    return function (source) {
        return source.pipe(operators_1.map(function (x) { return justClone(x); }));
    };
}
exports.clone = clone;
function flatten() {
    return function (source) {
        return source.pipe(operators_1.concatAll());
    };
}
exports.flatten = flatten;
function flatMap(fn) {
    return function (source) {
        return source.pipe(operators_1.mergeMap(function (item) { return rxjs_1.of(fn(item)); }), operators_1.concatAll());
    };
}
exports.flatMap = flatMap;
function where(conditions) {
    return function (source) {
        return source.pipe(operators_1.filter(function (item) {
            return Object.entries(conditions)
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return function (x) { return x[key] == value; };
            })
                .reduce(function (xs, x) { return xs && x(item); }, true);
        }));
    };
}
exports.where = where;
