"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function config(name) {
    var meta = document.querySelector("meta[name=\"" + name + "\"]");
    if (!meta) {
        throw Error("CONFIG: Missing [" + name + "] option.");
    }
    return meta.content;
}
exports.config = config;
