"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./NotFound.component");
var i2 = require("@angular/router");
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
exports.RenderType_NotFoundComponent = RenderType_NotFoundComponent;
function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "flex justify-center items-center w-screen h-screen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "w-2/3 md:w-1/3 xl:w-1/4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "mb-8 text-2xl text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Page "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "font-semibold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["not found"])), (_l()(), i0.ɵted(-1, null, [". "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "text-center text-lg text-gray-700 mb-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Oops, it seems that this page does not exist. "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-block btn-blue font-bold"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Go to dashboard "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "text-gray-500 text-center text-sm mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u00A92019 ContracTool. All Rights Reserved "]))], null, null); }
exports.View_NotFoundComponent_0 = View_NotFoundComponent_0;
function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i1.NotFoundComponent, [i2.Router], null, null)], null, null); }
exports.View_NotFoundComponent_Host_0 = View_NotFoundComponent_Host_0;
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
exports.NotFoundComponentNgFactory = NotFoundComponentNgFactory;
