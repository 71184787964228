"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var overlay_1 = require("@angular/cdk/overlay");
var portal_1 = require("@angular/cdk/portal");
var ConfirmComponent = /** @class */ (function () {
    function ConfirmComponent(overlay, container) {
        var _this = this;
        this.overlay = overlay;
        this.container = container;
        this.confirm = new core_1.EventEmitter();
        this.ref = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlay.scrollStrategies.block(),
            hasBackdrop: true,
            backdropClass: ['bg-almost-600'],
        });
        this.ref.backdropClick().subscribe(function () {
            _this.dismiss();
        });
    }
    ConfirmComponent.prototype.open = function () {
        if (!this.message) {
            return this.confirm.emit();
        }
        this.ref.attach(new portal_1.TemplatePortal(this.template, this.container));
    };
    ConfirmComponent.prototype.dismiss = function () {
        this.ref.detach();
    };
    ConfirmComponent.prototype.ngOnDestroy = function () {
        this.ref.dispose();
    };
    return ConfirmComponent;
}());
exports.ConfirmComponent = ConfirmComponent;
