"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var AdminMiddleware = /** @class */ (function () {
    function AdminMiddleware(store) {
        this.store = store;
        //
    }
    AdminMiddleware.prototype.canActivate = function () {
        return this.store.pipe(rx_1.choose(function (x) { return x.signedIn; }), operators_1.map(function (user) { return user.type == 'ADMIN'; }));
    };
    return AdminMiddleware;
}());
exports.AdminMiddleware = AdminMiddleware;
