"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var TranslatePipe = /** @class */ (function () {
    function TranslatePipe(store) {
        var _this = this;
        this.translations = {};
        store
            .pipe(rx_1.once(function (x) { return x.translations; }))
            .subscribe(function (translations) { return (_this.translations = translations); });
    }
    TranslatePipe.prototype.transform = function (input, substitutions) {
        if (substitutions === void 0) { substitutions = {}; }
        return substitute(substitutions)(this.translations[input] || input);
    };
    return TranslatePipe;
}());
exports.TranslatePipe = TranslatePipe;
function substitute(substitutions) {
    return Object.entries(substitutions)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return function (input) {
            return input.replace(new RegExp("(\\W|^):" + key + "(\\W|$)", 'g'), "$1" + value + "$2");
        };
    })
        .reduce(function (xs, x) { return function (input) { return x(xs(input)); }; }, function (x) { return x; });
}
