"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var overlay_1 = require("@angular/cdk/overlay");
var portal_1 = require("@angular/cdk/portal");
var ModalComponent = /** @class */ (function () {
    function ModalComponent(overlay, container) {
        this.overlay = overlay;
        this.container = container;
        this.size = 'lg';
        this.close = new core_1.EventEmitter();
        this.ref = null;
        //
    }
    ModalComponent.prototype.handleKeydown = function (event) {
        if (event['keyCode'] === 27) {
            this.close.emit();
        }
    };
    ModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return (_this.ref = _this.create()); });
    };
    ModalComponent.prototype.ngOnDestroy = function () {
        if (this.ref) {
            this.ref.dispose();
        }
    };
    ModalComponent.prototype.create = function () {
        var ref = this.overlay.create({
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });
        ref.attach(new portal_1.TemplatePortal(this.template, this.container));
        return ref;
    };
    return ModalComponent;
}());
exports.ModalComponent = ModalComponent;
