"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Tabs_component_1 = require("@modules/Support/Tabs.component");
var TabComponent = /** @class */ (function () {
    function TabComponent(parent) {
        this.parent = parent;
        //
    }
    TabComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.parent.add(_this); });
    };
    Object.defineProperty(TabComponent.prototype, "active", {
        get: function () {
            return this.parent.active == this;
        },
        enumerable: true,
        configurable: true
    });
    return TabComponent;
}());
exports.TabComponent = TabComponent;
