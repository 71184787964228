"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var store_1 = require("@ngrx/store");
var app_reducer_1 = require("./app.reducer");
var AppComponent = /** @class */ (function () {
    function AppComponent(store) {
        this.store = store;
        this.store.dispatch(new app_reducer_1.TranslationsLoad());
    }
    return AppComponent;
}());
exports.AppComponent = AppComponent;
