"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaginatedView_component_1 = require("./PaginatedView.component");
var PaginatedViewSearchComponent = /** @class */ (function () {
    function PaginatedViewSearchComponent(parent) {
        this.parent = parent;
        //
    }
    return PaginatedViewSearchComponent;
}());
exports.PaginatedViewSearchComponent = PaginatedViewSearchComponent;
