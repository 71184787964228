"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Tribute = require("tributejs");
var url_1 = require("@utils/url");
var http_1 = require("@angular/common/http");
var MentionsDirective = /** @class */ (function () {
    function MentionsDirective(ref, http) {
        var _this = this;
        this.ref = ref;
        this.http = http;
        this.ngModelChange = new core_1.EventEmitter();
        this.ref.nativeElement.addEventListener('tribute-replaced', function () {
            _this.ngModelChange.emit(_this.ref.nativeElement.value);
        });
    }
    MentionsDirective.prototype.ngOnInit = function () {
        tribute(this.http).attach(this.ref.nativeElement);
    };
    return MentionsDirective;
}());
exports.MentionsDirective = MentionsDirective;
function tribute(http) {
    return new Tribute({
        lookup: function (user) {
            return user.username + user.name;
        },
        values: function (phrase, callback) {
            http.get(url_1.api('/users'), {
                params: { phrase: phrase },
            }).subscribe(function (_a) {
                var data = _a.data;
                return callback(data);
            });
        },
        selectTemplate: function (result) {
            return result ? "@" + result.original.username : '';
        },
        menuItemTemplate: function (_a) {
            var user = _a.original;
            return "<div class=\"flex items-center py-3 px-4 cursor-pointer\">\n                <img\n                    src=\"" + url_1.sign(user.avatar_url) + "\"\n                    alt=\"" + user.name + "\"\n                    class=\"block w-8 h-8 mr-3 rounded-full\"\n                />\n\n                <span class=\"name font-medium\">" + user.name + "</span>\n            </div>";
        },
        noMatchTemplate: function () {
            return "<div class=\"py-3 px-4 text-gray-700\">No matches! Typo?</div>";
        },
    });
}
