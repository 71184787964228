"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_1 = require("@utils/auth");
var http_1 = require("@angular/common/http");
var url_1 = require("@utils/url");
var general_1 = require("@utils/general");
var UploadComponent = /** @class */ (function () {
    function UploadComponent(http, backend) {
        this.http = http;
        this.backend = backend;
        this.upload = new core_1.EventEmitter();
        this.fileOver = false;
        this.uploading = false;
        //
    }
    UploadComponent.prototype.select = function (e) {
        var file = e.target.files[0];
        if (!file) {
            return;
        }
        this._upload(file);
    };
    UploadComponent.prototype.drop = function (e) {
        var file = e.dataTransfer.files[0];
        if (!file) {
            return;
        }
        this._upload(file);
    };
    UploadComponent.prototype._upload = function (file) {
        var _this = this;
        this.uploading = true;
        upload(new http_1.HttpClient(this.backend), file).then(function (response) {
            _this.http
                .post(url_1.api(_this.url), { file: response })
                .subscribe(function (response) {
                _this.upload.emit(response.data);
                _this.uploading = false;
            });
        });
    };
    return UploadComponent;
}());
exports.UploadComponent = UploadComponent;
function upload(http, file) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, http
                        .post('/api/files/request', {
                        bucket: '',
                        content_type: file.type,
                        expires: '',
                        visibility: '',
                    }, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + auth_1.getToken(),
                        },
                    })
                        .toPromise()];
                case 1:
                    response = _a.sent();
                    if (!response.local) return [3 /*break*/, 3];
                    return [4 /*yield*/, http
                            .post(response.url, formData({ file: file }), { headers: response.headers })
                            .toPromise()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, http
                        .put(response.url, file, { headers: general_1.drop(response.headers, function (key) { return key === 'Host'; }) })
                        .toPromise()];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [2 /*return*/, { path: response.key, name: file.name }];
            }
        });
    });
}
function formData(input) {
    var data = new FormData();
    Object.entries(input).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        data.append(key, value);
    });
    return data;
}
