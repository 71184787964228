"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FilterTemplatesLoad = /** @class */ (function () {
    function FilterTemplatesLoad(payload) {
        this.payload = payload;
        this.type = 'FILTER_TEMPLATES_LOAD';
        //
    }
    return FilterTemplatesLoad;
}());
exports.FilterTemplatesLoad = FilterTemplatesLoad;
var FilterTemplatesLoaded = /** @class */ (function () {
    function FilterTemplatesLoaded(payload) {
        this.payload = payload;
        this.type = 'FILTER_TEMPLATES_LOADED';
        //
    }
    return FilterTemplatesLoaded;
}());
exports.FilterTemplatesLoaded = FilterTemplatesLoaded;
function filterTemplates(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'FILTER_TEMPLATES_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.filterTemplates = filterTemplates;
