"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./Icon.component.ngfactory");
var i3 = require("./Icon.component");
var i4 = require("./Modal.component");
var i5 = require("@angular/cdk/overlay");
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
exports.RenderType_ModalComponent = RenderType_ModalComponent;
function View_ModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "w-screen h-screen bg-almost-600 overflow-scroll"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "w-full flex justify-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "card relative m-8"], ["data-cy-modal", ""]], [[2, "w-full", null], [2, "w-360", null], [2, "w-240", null], [2, "w-160", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(5, { width: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "absolute top-0 right-0 p-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "icon", [["class", "cursor-pointer text-gray-600"], ["name", "times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(8, 49152, null, 0, i3.IconComponent, [], { name: [0, "name"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = ((_co.size == "xxl") ? _ck(_v, 5, 0, "calc(100% - 60px)") : i0.ɵEMPTY_MAP); _ck(_v, 4, 0, currVal_4); var currVal_5 = "times"; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size == "xxl"); var currVal_1 = (_co.size == "xl"); var currVal_2 = (_co.size == "lg"); var currVal_3 = (_co.size == "sm"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { template: 0 }), (_l()(), i0.ɵand(0, [[1, 2], ["template", 2]], null, 0, null, View_ModalComponent_1))], null, null); }
exports.View_ModalComponent_0 = View_ModalComponent_0;
function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "modal", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 245760, null, 0, i4.ModalComponent, [i5.Overlay, i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ModalComponent_Host_0 = View_ModalComponent_Host_0;
var ModalComponentNgFactory = i0.ɵccf("modal", i4.ModalComponent, View_ModalComponent_Host_0, { size: "size" }, { close: "close" }, ["*"]);
exports.ModalComponentNgFactory = ModalComponentNgFactory;
