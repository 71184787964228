"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ErrorBag_1 = require("@utils/ErrorBag");
var SuppliersFormComponent = /** @class */ (function () {
    function SuppliersFormComponent() {
    }
    SuppliersFormComponent.prototype.addContact = function () {
        this.supplier.contacts.push({});
    };
    SuppliersFormComponent.prototype.removeContact = function (index) {
        this.supplier.contacts.splice(index, 1);
    };
    return SuppliersFormComponent;
}());
exports.SuppliersFormComponent = SuppliersFormComponent;
