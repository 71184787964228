"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Autoresize_directive_1 = require("./Autoresize.directive");
var Mentions_directive_1 = require("./Mentions.directive");
var imports = [Autoresize_directive_1.AutoresizeDirective, Mentions_directive_1.MentionsDirective];
var DirectivesModule = /** @class */ (function () {
    function DirectivesModule() {
    }
    return DirectivesModule;
}());
exports.DirectivesModule = DirectivesModule;
