"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var ChecksumService = /** @class */ (function () {
    function ChecksumService(router) {
        this.router = router;
        //
    }
    ChecksumService.prototype.handle = function (event) {
        var version = event.headers.get('X-Application-Release');
        var existing = localStorage.getItem('app-release');
        if (!version) {
            return;
        }
        if (!existing) {
            localStorage.setItem('app-release', version);
            return;
        }
        if (existing != version) {
            this.router.navigate(['', { outlets: { secondary: ['new-version'] } }]);
        }
    };
    return ChecksumService;
}());
exports.ChecksumService = ChecksumService;
