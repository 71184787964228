"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Components
 */
var Icon_component_1 = require("./Icon.component");
var Modal_component_1 = require("./Modal.component");
var Confirm_component_1 = require("./Confirm.component");
var ConfirmModal_component_1 = require("./ConfirmModal.component");
var SearchBox_component_1 = require("./SearchBox.component");
var DatePicker_component_1 = require("./DatePicker.component");
var UserAvatar_component_1 = require("./UserAvatar.component");
var ContextMenu_component_1 = require("./ContextMenu.component");
var Tab_component_1 = require("./Tab.component");
var Tabs_component_1 = require("./Tabs.component");
var Field_component_1 = require("./Field.component");
var DateRange_component_1 = require("./DateRange.component");
var CustomProjectField_component_1 = require("./Form/CustomProjectField.component");
var Upload_component_1 = require("./Upload.component");
var dashboard_grid_component_1 = require("../../pages/dashboard/dashboard-grid.component");
var dashboard_list_component_1 = require("../../pages/dashboard/dashboard-list.component");
var Toggle_component_1 = require("./Toggle.component");
var suppliers_form_component_1 = require("../../pages/suppliers/components/suppliers-form.component");
var report_details_component_1 = require("../../pages/reports/report-details.component");
var manage_tags_form_component_1 = require("../../pages/project/project-detail/project-info/manage-tags-form.component");
var CustomField_component_1 = require("./Form/CustomField.component");
var UiForm_component_1 = require("./UiForm.component");
var UploadBrowse_component_1 = require("./UploadBrowse.component");
var tags_component_1 = require("../../pages/project/components/tags.component");
var TypeaheadProvider_component_1 = require("./TypeaheadProvider.component");
var Typeahead_component_1 = require("./Typeahead.component");
var statistics_component_1 = require("../../pages/project/components/statistics.component");
var users_detail_component_1 = require("../../pages/users/components/users-detail.component");
var filters_component_1 = require("../../pages/dashboard/filters/filters.component");
var filters_card_component_1 = require("../../pages/dashboard/filters/filters-card.component");
var PaginatedView_component_1 = require("./PaginatedView/PaginatedView.component");
var PaginatedViewSearch_component_1 = require("./PaginatedView/PaginatedViewSearch.component");
var PaginatedViewPagination_component_1 = require("./PaginatedView/PaginatedViewPagination.component");
var Team_component_1 = require("./Team.component");
/**
 * List of all imports.
 */
var imports = [
    Icon_component_1.IconComponent,
    Tab_component_1.TabComponent,
    Tabs_component_1.TabsComponent,
    Modal_component_1.ModalComponent,
    Confirm_component_1.ConfirmComponent,
    ConfirmModal_component_1.ConfirmModalComponent,
    SearchBox_component_1.SearchBoxComponent,
    DatePicker_component_1.DatePickerComponent,
    UserAvatar_component_1.UserAvatarComponent,
    ContextMenu_component_1.ContextMenuComponent,
    Toggle_component_1.ToggleComponent,
    Field_component_1.FieldComponent,
    DateRange_component_1.DateRangeComponent,
    CustomField_component_1.CustomFieldComponent,
    CustomProjectField_component_1.CustomProjectFieldComponent,
    Upload_component_1.UploadComponent,
    UploadBrowse_component_1.UploadBrowseComponent,
    dashboard_list_component_1.DashboardListComponent,
    dashboard_grid_component_1.DashboardGridComponent,
    suppliers_form_component_1.SuppliersFormComponent,
    report_details_component_1.ReportDetailsComponent,
    UiForm_component_1.UiFormComponent,
    tags_component_1.TagsComponent,
    manage_tags_form_component_1.TagFormComponent,
    Typeahead_component_1.TypeaheadComponent,
    TypeaheadProvider_component_1.TypeaheadProviderComponent,
    PaginatedView_component_1.PaginatedViewComponent,
    PaginatedViewSearch_component_1.PaginatedViewSearchComponent,
    PaginatedViewPagination_component_1.PaginatedViewPaginationComponent,
    statistics_component_1.ProjectStatisticsComponent,
    Team_component_1.TeamComponent,
    Team_component_1.TeamInnerComponent,
    users_detail_component_1.UsersDetailComponent,
    filters_component_1.FiltersComponent,
    filters_card_component_1.FilterCardComponent,
];
var SupportModule = /** @class */ (function () {
    function SupportModule() {
    }
    return SupportModule;
}());
exports.SupportModule = SupportModule;
