"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IconComponent = /** @class */ (function () {
    function IconComponent() {
        this.size = 4;
    }
    Object.defineProperty(IconComponent.prototype, "identifier", {
        get: function () {
            return "#" + this.name;
        },
        enumerable: true,
        configurable: true
    });
    return IconComponent;
}());
exports.IconComponent = IconComponent;
