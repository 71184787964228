"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var date_fns_1 = require("date-fns");
var general_1 = require("@utils/general");
var DateRangeComponent = /** @class */ (function () {
    function DateRangeComponent() {
        this.from = null;
        this.to = null;
        this.period = 'custom';
        this.hidePeriod = false;
        this.pick = new core_1.EventEmitter();
        this.periods = [
            {
                label: 'General',
                items: [
                    {
                        key: 'week',
                        label: 'last week',
                    },
                    {
                        key: 'month',
                        label: 'last month',
                    },
                    {
                        key: 'quarter',
                        label: 'last quarter',
                    },
                    {
                        key: 'year',
                        label: 'last year',
                    },
                    {
                        key: 'custom',
                        label: 'custom',
                    },
                ],
            },
            {
                label: 'Quarters',
                items: [
                    {
                        key: 'q1',
                        label: 'Q1: 2nd Feb - 3rd May',
                    },
                    {
                        key: 'q2',
                        label: 'Q2: 4th May - 2nd Aug',
                    },
                    {
                        key: 'q3',
                        label: 'Q3: 3rd Aug - 1st Nov',
                    },
                    {
                        key: 'q4',
                        label: 'Q4: 2nd Nov - 31st Jan',
                    },
                ],
            },
        ];
    }
    DateRangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.updateRange(_this.period); }, 50);
    };
    DateRangeComponent.prototype.updateRange = function (period) {
        this.period = period;
        this.sync();
        this.pick.emit(general_1.dropFalsy({ from: this.from, to: this.to }));
    };
    DateRangeComponent.prototype.customUpdate = function (_a) {
        var from = _a.from, to = _a.to;
        this.period = 'custom';
        this.pick.emit(general_1.dropFalsy({ from: from, to: to }));
    };
    DateRangeComponent.prototype.sync = function () {
        switch (this.period) {
            case 'week':
                this.from = date_fns_1.format(date_fns_1.subWeeks(new Date(), 1), 'yyyy-MM-dd');
                this.to = date_fns_1.format(new Date(), 'yyyy-MM-dd');
                break;
            case 'month':
                this.from = date_fns_1.format(date_fns_1.subMonths(new Date(), 1), 'yyyy-MM-dd');
                this.to = date_fns_1.format(new Date(), 'yyyy-MM-dd');
                break;
            case 'quarter':
                this.from = date_fns_1.format(date_fns_1.subMonths(new Date(), 3), 'yyyy-MM-dd');
                this.to = date_fns_1.format(new Date(), 'yyyy-MM-dd');
                break;
            case 'year':
                this.from = date_fns_1.format(date_fns_1.subYears(new Date(), 1), 'yyyy-MM-dd');
                this.to = date_fns_1.format(new Date(), 'yyyy-MM-dd');
                break;
            case 'q1':
                this.from = '2019-02-02';
                this.to = '2019-05-03';
                break;
            case 'q2':
                this.from = '2019-05-04';
                this.to = '2019-08-02';
                break;
            case 'q3':
                this.from = '2019-08-03';
                this.to = '2019-11-01';
                break;
            case 'q4':
                this.from = '2019-11-02';
                this.to = '2020-01-31';
                break;
        }
    };
    return DateRangeComponent;
}());
exports.DateRangeComponent = DateRangeComponent;
