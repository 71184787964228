"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var array_1 = require("@utils/array");
var ErrorBag = /** @class */ (function () {
    function ErrorBag(errors) {
        if (errors === void 0) { errors = {}; }
        this.errors = errors;
        //
    }
    ErrorBag.empty = function () {
        return new this();
    };
    ErrorBag.fromResponse = function (_a) {
        var r = _a.error;
        return new this(r.errors);
    };
    ErrorBag.fits = function (r) {
        return r && r.status >= 400 && r.status < 500 && r.error && r.error.errors;
    };
    ErrorBag.prototype.get = function (key) {
        return this.errors[key] || [];
    };
    ErrorBag.prototype.first = function (key) {
        return array_1.first(this.errors[key] || []);
    };
    ErrorBag.prototype.has = function (key) {
        return !!this.errors[key];
    };
    return ErrorBag;
}());
exports.ErrorBag = ErrorBag;
