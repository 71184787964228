"use strict";
/**
 * BROWSER POLYFILLS
 */
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js");
require("classlist.js");
/** IE10 and IE11 requires the following to support `@angular/animation`. */
require("web-animations-js");
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 * Included with Angular CLI.
 */
require("zone.js/dist/zone");
// IE 11 requires this
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
