"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var overlay_1 = require("@angular/cdk/overlay");
var portal_1 = require("@angular/cdk/portal");
var ContextMenuComponent = /** @class */ (function () {
    function ContextMenuComponent(overlay, container) {
        this.overlay = overlay;
        this.container = container;
        //
    }
    ContextMenuComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.root)
                .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]),
            scrollStrategy: this.overlay.scrollStrategies.reposition({
                autoClose: true,
                scrollThrottle: 0,
            }),
            hasBackdrop: true,
        });
        this.overlayRef.backdropClick().subscribe(function () { return _this.hide(); });
    };
    ContextMenuComponent.prototype.toggle = function (event) {
        event.stopPropagation();
        if (!this.displayed) {
            this.show();
        }
        else {
            this.hide();
        }
    };
    ContextMenuComponent.prototype.show = function () {
        if (this.overlayRef) {
            this.overlayRef.attach(new portal_1.TemplatePortal(this.template, this.container));
        }
    };
    ContextMenuComponent.prototype.hide = function () {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    };
    ContextMenuComponent.prototype.ngOnDestroy = function () {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    };
    Object.defineProperty(ContextMenuComponent.prototype, "displayed", {
        get: function () {
            return this.overlayRef ? this.overlayRef.hasAttached() : false;
        },
        enumerable: true,
        configurable: true
    });
    return ContextMenuComponent;
}());
exports.ContextMenuComponent = ContextMenuComponent;
