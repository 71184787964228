"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var NotFoundComponent = /** @class */ (function () {
    function NotFoundComponent(router) {
        this.router = router;
    }
    NotFoundComponent.prototype.back = function () {
        this.router.navigate(['dashboard']);
    };
    return NotFoundComponent;
}());
exports.NotFoundComponent = NotFoundComponent;
