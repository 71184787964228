"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var ConfirmModalComponent = /** @class */ (function () {
    function ConfirmModalComponent(router, route, location) {
        this.router = router;
        this.route = route;
        this.location = location;
        //
    }
    ConfirmModalComponent.prototype.dismiss = function () {
        this.location.back();
    };
    ConfirmModalComponent.prototype.confirm = function () {
        this.router.navigateByUrl(this.route.snapshot.queryParams.intended);
    };
    return ConfirmModalComponent;
}());
exports.ConfirmModalComponent = ConfirmModalComponent;
