"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ErrorBag_1 = require("@utils/ErrorBag");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ngx_toastr_1 = require("ngx-toastr");
var Translate_pipe_1 = require("../../pipes/Translate.pipe");
var UiFormComponent = /** @class */ (function () {
    function UiFormComponent(http, toastr, trans) {
        this.http = http;
        this.toastr = toastr;
        this.trans = trans;
        this.enter = false;
        this.then = new core_1.EventEmitter();
        this.fail = new core_1.EventEmitter();
        this.errors = ErrorBag_1.ErrorBag.empty();
        //
    }
    UiFormComponent.prototype.submit = function (event) {
        var _this = this;
        event.preventDefault();
        event.stopPropagation();
        if (!this.method) {
            throw new Error('UiFormComponent: missing [method] prop.');
        }
        if (!this.url) {
            throw new Error('UiFormComponent: missing [url] prop.');
        }
        this.http[this.method](this.url, this.data)
            .pipe(operators_1.catchError(function (response) {
            if (ErrorBag_1.ErrorBag.fits(response)) {
                _this.errors = ErrorBag_1.ErrorBag.fromResponse(response);
                _this.toastr.error(_this.trans.transform('There are some required fields missing!'), _this.trans.transform('Please check the form.'));
                return rxjs_1.EMPTY;
            }
            return rxjs_1.throwError(response);
        }))
            .subscribe(function (response) {
            _this.errors = ErrorBag_1.ErrorBag.empty();
            return _this.then.emit(response.data);
        }, function (response) {
            if (_this.fail.observers.length == 0) {
                throw response;
            }
            return _this.fail.emit(response);
        });
    };
    UiFormComponent.prototype.submitEnter = function (event) {
        if (!this.enter) {
            return;
        }
        this.submit(event);
    };
    return UiFormComponent;
}());
exports.UiFormComponent = UiFormComponent;
