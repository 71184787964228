"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaginatedView_component_1 = require("./PaginatedView.component");
var PaginatedViewPaginationComponent = /** @class */ (function () {
    function PaginatedViewPaginationComponent(parent) {
        this.parent = parent;
        //
    }
    return PaginatedViewPaginationComponent;
}());
exports.PaginatedViewPaginationComponent = PaginatedViewPaginationComponent;
