"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AutoresizeDirective = /** @class */ (function () {
    function AutoresizeDirective(el) {
        this.el = el;
        //
    }
    AutoresizeDirective.prototype.ngOnChanges = function () {
        var _this = this;
        // resize immediately, so there is no glitching.
        this.resize();
        // sometimes, angular takes a while to re-render
        setTimeout(function () { return _this.resize(); });
    };
    AutoresizeDirective.prototype.resize = function () {
        var el = this.el.nativeElement;
        el.style.height = 'auto';
        el.style.height = el.scrollHeight + "px";
    };
    return AutoresizeDirective;
}());
exports.AutoresizeDirective = AutoresizeDirective;
