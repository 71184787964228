"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var routes_1 = require("@utils/routes");
var auth_1 = require("@utils/auth");
var app_reducer_1 = require("../app.reducer");
var AuthMiddleware = /** @class */ (function () {
    function AuthMiddleware(router, http, store) {
        this.router = router;
        this.http = http;
        this.store = store;
        //
    }
    AuthMiddleware.prototype.canActivate = function (_) {
        var _this = this;
        return this.http.get(routes_1.CONFIGURATION_URL()).pipe(operators_1.tap(function (_a) {
            var payload = _a.data;
            return _this.store.dispatch(new app_reducer_1.ConfigurationLoaded(payload));
        }), operators_1.map(function () { return true; }), operators_1.catchError(function (error) {
            auth_1.clearToken();
            _this.router.navigate(['login']);
            return rxjs_1.throwError(error);
        }));
    };
    return AuthMiddleware;
}());
exports.AuthMiddleware = AuthMiddleware;
