"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Sentry = require("@sentry/browser");
var config_1 = require("@utils/config");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var ErrorHandler = /** @class */ (function () {
    function ErrorHandler(injector) {
        this.injector = injector;
        //
    }
    ErrorHandler.prototype.handleError = function (err) {
        var _this = this;
        if (process.env.NODE_ENV !== 'production') {
            throw err;
        }
        // Ignore 500s, since these are already reported from Laravel.
        // 401s are not that interesting.
        if (err instanceof http_1.HttpErrorResponse && [500, 401].includes(err.status)) {
            return;
        }
        Sentry.init({
            dsn: config_1.config('sentry_dsn'),
            release: config_1.config('app_release'),
        });
        this.setUserContext();
        if (this.isMissingChunksError(err)) {
            this.injector.get(core_1.NgZone).run(function () {
                _this.injector
                    .get(router_1.Router)
                    .navigate(['', { outlets: { secondary: ['new-version'] } }]);
            });
            return;
        }
        Sentry.captureException(err.originalError || err);
    };
    ErrorHandler.prototype.isMissingChunksError = function (err) {
        return new RegExp('Error: Loading chunk [0-9]+ failed').test(err.message);
    };
    ErrorHandler.prototype.setUserContext = function () {
        this.injector
            .get(store_1.Store)
            .pipe(rx_1.choose(function (x) { return x.signedIn; }))
            .subscribe(function (user) {
            return Sentry.setUser({
                id: String(user.id),
            });
        });
    };
    return ErrorHandler;
}());
exports.ErrorHandler = ErrorHandler;
