"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var url_1 = require("@utils/url");
var general_1 = require("@utils/general");
var TypeaheadProviderComponent = /** @class */ (function () {
    function TypeaheadProviderComponent(http) {
        var _this = this;
        this.http = http;
        this.queryParams = {};
        this.loading = false;
        this.default$ = new rxjs_1.BehaviorSubject([]);
        this.typeahead$ = new rxjs_1.Subject();
        this.items$ = rxjs_1.merge(this.default$, this.typeahead$.pipe(operators_1.filter(function (x) { return x !== null; }), operators_1.debounceTime(200), operators_1.distinctUntilChanged(), operators_1.tap(function () { return (_this.loading = true); }), operators_1.map(function (phrase) { return url_1.encodeValues(general_1.dropNullish(__assign({}, _this.queryParams, { phrase: phrase }))); }), operators_1.switchMap(function (params) { return _this.http.get(url_1.api(_this.url), { params: params }); }), operators_1.tap(function () { return (_this.loading = false); }), operators_1.map(function (_a) {
            var data = _a.data;
            return data;
        })));
        //
    }
    Object.defineProperty(TypeaheadProviderComponent.prototype, "current", {
        set: function (x) {
            if (!x)
                return;
            this.default$.next(Array.isArray(x) ? x : [x]);
        },
        enumerable: true,
        configurable: true
    });
    return TypeaheadProviderComponent;
}());
exports.TypeaheadProviderComponent = TypeaheadProviderComponent;
