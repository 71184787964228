"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FilterCardComponent = /** @class */ (function () {
    function FilterCardComponent() {
        this.edit = new core_1.EventEmitter();
    }
    return FilterCardComponent;
}());
exports.FilterCardComponent = FilterCardComponent;
