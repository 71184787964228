"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AbstractValueAccessor_1 = require("@utils/AbstractValueAccessor");
var ToggleComponent = /** @class */ (function (_super) {
    __extends(ToggleComponent, _super);
    function ToggleComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toggle = new core_1.EventEmitter();
        return _this;
    }
    Object.defineProperty(ToggleComponent.prototype, "styles", {
        get: function () {
            return this.value ? { transform: 'translateX(125%)' } : {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToggleComponent.prototype, "classes", {
        get: function () {
            return { 'bg-blue-500': this.value, 'bg-gray-500': !this.value };
        },
        enumerable: true,
        configurable: true
    });
    ToggleComponent.prototype._toggle = function () {
        this.value = !this.value;
        this.toggle.emit(this.value);
    };
    return ToggleComponent;
}(AbstractValueAccessor_1.AbstractValueAccessor));
exports.ToggleComponent = ToggleComponent;
