"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../modules/Support/Modal.component.ngfactory");
var i2 = require("../modules/Support/Modal.component");
var i3 = require("@angular/cdk/overlay");
var i4 = require("./NewVersionAvailable.component");
var i5 = require("@angular/router");
var styles_NewVersionAvailableComponent = [];
var RenderType_NewVersionAvailableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewVersionAvailableComponent, data: {} });
exports.RenderType_NewVersionAvailableComponent = RenderType_NewVersionAvailableComponent;
function View_NewVersionAvailableComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 16, "modal", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(1, 245760, null, 0, i2.ModalComponent, [i3.Overlay, i0.ViewContainerRef], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 14, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "card-header flex justify-between alert-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "card-title text-xl font-light"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["New version available!"])), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "card-body text-gray-800"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["class", "mb-4 font-medium"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["A new version of ContracTool is now available."])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" We update the app regularly to make it faster and more reliable for you. Simply click on the "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "italic"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reload application"])), (_l()(), i0.ɵted(-1, null, [" button and the new version will be launched. "])), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "card-footer text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-blue"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Reload application "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NewVersionAvailableComponent_0 = View_NewVersionAvailableComponent_0;
function View_NewVersionAvailableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NewVersionAvailableComponent_0, RenderType_NewVersionAvailableComponent)), i0.ɵdid(1, 49152, null, 0, i4.NewVersionAvailableComponent, [i5.Router], null, null)], null, null); }
exports.View_NewVersionAvailableComponent_Host_0 = View_NewVersionAvailableComponent_Host_0;
var NewVersionAvailableComponentNgFactory = i0.ɵccf("ng-component", i4.NewVersionAvailableComponent, View_NewVersionAvailableComponent_Host_0, {}, {}, []);
exports.NewVersionAvailableComponentNgFactory = NewVersionAvailableComponentNgFactory;
