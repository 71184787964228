"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var AbstractValueAccessor = /** @class */ (function () {
    function AbstractValueAccessor() {
        this.changed = new Array();
        this.touched = new Array();
    }
    Object.defineProperty(AbstractValueAccessor.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (value) {
            this.writeValue(value);
        },
        enumerable: true,
        configurable: true
    });
    AbstractValueAccessor.prototype.touch = function () {
        this.touched.forEach(function (f) { return f(); });
    };
    AbstractValueAccessor.prototype.writeValue = function (value) {
        if (this.innerValue === value) {
            return;
        }
        this.innerValue = value;
        this.changed.forEach(function (f) { return f(value); });
    };
    AbstractValueAccessor.prototype.registerOnChange = function (fn) {
        this.changed.push(fn);
    };
    AbstractValueAccessor.prototype.registerOnTouched = function (fn) {
        this.touched.push(fn);
    };
    return AbstractValueAccessor;
}());
exports.AbstractValueAccessor = AbstractValueAccessor;
function provider(type) {
    return {
        provide: forms_1.NG_VALUE_ACCESSOR,
        useExisting: core_1.forwardRef(function () { return type; }),
        multi: true,
    };
}
exports.provider = provider;
