"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DashboardListComponent = /** @class */ (function () {
    function DashboardListComponent() {
        this.projects = [];
        /**
         * Sorting.
         */
        this.sorting = { by: '', direction: 'asc' };
        this.sort = new core_1.EventEmitter();
    }
    Object.defineProperty(DashboardListComponent.prototype, "icon", {
        get: function () {
            return this.sorting.direction == 'asc' ? 'arrow-up' : 'arrow-down';
        },
        enumerable: true,
        configurable: true
    });
    return DashboardListComponent;
}());
exports.DashboardListComponent = DashboardListComponent;
