"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var array_1 = require("@utils/array");
var FiltersLoad = /** @class */ (function () {
    function FiltersLoad(payload) {
        this.payload = payload;
        this.type = 'FILTERS_LOAD';
        //
    }
    return FiltersLoad;
}());
exports.FiltersLoad = FiltersLoad;
var FiltersLoaded = /** @class */ (function () {
    function FiltersLoaded(payload) {
        this.payload = payload;
        this.type = 'FILTERS_LOADED';
        //
    }
    return FiltersLoaded;
}());
exports.FiltersLoaded = FiltersLoaded;
var FiltersUpdated = /** @class */ (function () {
    function FiltersUpdated(payload) {
        this.payload = payload;
        this.type = 'FILTERS_UPDATED';
        //
    }
    return FiltersUpdated;
}());
exports.FiltersUpdated = FiltersUpdated;
function filters(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'FILTERS_LOADED':
            return action.payload;
        case 'FILTERS_UPDATED':
            return array_1.not(state, { id: action.payload.id }).concat([action.payload]);
        default:
            return state;
    }
}
exports.filters = filters;
