"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FieldComponent = /** @class */ (function () {
    function FieldComponent() {
        this.name = '';
        this.label = '';
        this.legend = '';
        this.errors = [];
    }
    return FieldComponent;
}());
exports.FieldComponent = FieldComponent;
