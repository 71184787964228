"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var general_1 = require("@utils/general");
var ngx_toastr_1 = require("ngx-toastr");
var Translate_pipe_1 = require("../../pipes/Translate.pipe");
var app_reducer_1 = require("../../app.reducer");
var ReportDetailsComponent = /** @class */ (function () {
    function ReportDetailsComponent(router, route, store, toastr, trans) {
        this.router = router;
        this.route = route;
        this.store = store;
        this.toastr = toastr;
        this.trans = trans;
        this.detail$ = this.store.pipe(rx_1.choose(function (x) { return x.reportDetail; }));
        //
    }
    ReportDetailsComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new app_reducer_1.ReportDetailClear());
    };
    ReportDetailsComponent.prototype.close = function () {
        this.router.navigate(['..'], { relativeTo: this.route });
    };
    ReportDetailsComponent.prototype.load = function (_a) {
        var page = _a.page;
        this.store.dispatch(new app_reducer_1.ReportDetailLoad(__assign({}, this.route.snapshot.queryParams, (page ? { page: page } : {}))));
    };
    ReportDetailsComponent.prototype._export = function () {
        var params = general_1.dropFalsy(__assign({}, this.route.snapshot.queryParams, { filter_id: this.route.snapshot.queryParams.filter_id }));
        this.toastr.info(this.trans.transform("You will receive a notification once it's ready."), this.trans.transform('Your export is being prepared.'));
        this.store.dispatch(new app_reducer_1.ExportCreate(params));
    };
    return ReportDetailsComponent;
}());
exports.ReportDetailsComponent = ReportDetailsComponent;
