"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var KEY = 'token';
function getToken() {
    return localStorage.getItem(KEY);
}
exports.getToken = getToken;
function setToken(token) {
    localStorage.setItem(KEY, token);
}
exports.setToken = setToken;
function clearToken() {
    localStorage.removeItem(KEY);
}
exports.clearToken = clearToken;
