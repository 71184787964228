"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
function tap(arg, fn) {
    return fn(arg);
}
exports.tap = tap;
function drop(input, fn) {
    return Object.entries(input)
        .filter(function (_a) {
        var k = _a[0], v = _a[1];
        return !fn(k, v);
    })
        .reduce(function (o, _a) {
        var _b;
        var k = _a[0], v = _a[1];
        return (__assign({}, o, (_b = {}, _b[k] = v, _b)));
    }, {});
}
exports.drop = drop;
function dropFalsy(input) {
    return drop(input, function (_, v) { return !v; });
}
exports.dropFalsy = dropFalsy;
function dropNullish(input) {
    return drop(input, function (_, v) { return v === null || v === undefined || v === ''; });
}
exports.dropNullish = dropNullish;
