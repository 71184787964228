"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var general_1 = require("@utils/general");
var array_1 = require("@utils/array");
var LibraryLoad = /** @class */ (function () {
    function LibraryLoad() {
        this.type = 'LIBRARY_LOAD';
    }
    return LibraryLoad;
}());
exports.LibraryLoad = LibraryLoad;
var LibraryLoaded = /** @class */ (function () {
    function LibraryLoaded(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_LOADED';
        //
    }
    return LibraryLoaded;
}());
exports.LibraryLoaded = LibraryLoaded;
var LibraryCreated = /** @class */ (function () {
    function LibraryCreated(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_CREATED';
        //
    }
    return LibraryCreated;
}());
exports.LibraryCreated = LibraryCreated;
var LibraryUpdated = /** @class */ (function () {
    function LibraryUpdated(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_UPDATED';
        //
    }
    return LibraryUpdated;
}());
exports.LibraryUpdated = LibraryUpdated;
var LibraryRemove = /** @class */ (function () {
    function LibraryRemove(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_REMOVE';
        //
    }
    return LibraryRemove;
}());
exports.LibraryRemove = LibraryRemove;
var LibraryRemoved = /** @class */ (function () {
    function LibraryRemoved(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_REMOVED';
        //
    }
    return LibraryRemoved;
}());
exports.LibraryRemoved = LibraryRemoved;
var LibraryDocumentUpdated = /** @class */ (function () {
    function LibraryDocumentUpdated(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_UPDATED';
        //
    }
    return LibraryDocumentUpdated;
}());
exports.LibraryDocumentUpdated = LibraryDocumentUpdated;
var LibraryDocumentVersionCommentCreate = /** @class */ (function () {
    function LibraryDocumentVersionCommentCreate(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_VERSION_COMMENT_CREATE';
        //
    }
    return LibraryDocumentVersionCommentCreate;
}());
exports.LibraryDocumentVersionCommentCreate = LibraryDocumentVersionCommentCreate;
var LibraryDocumentVersionCommentCreated = /** @class */ (function () {
    function LibraryDocumentVersionCommentCreated(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_VERSION_COMMENT_CREATED';
        //
    }
    return LibraryDocumentVersionCommentCreated;
}());
exports.LibraryDocumentVersionCommentCreated = LibraryDocumentVersionCommentCreated;
var LibraryDocumentVersionCommentUpdate = /** @class */ (function () {
    function LibraryDocumentVersionCommentUpdate(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_VERSION_COMMENT_UPDATE';
        //
    }
    return LibraryDocumentVersionCommentUpdate;
}());
exports.LibraryDocumentVersionCommentUpdate = LibraryDocumentVersionCommentUpdate;
var LibraryDocumentVersionCommentUpdated = /** @class */ (function () {
    function LibraryDocumentVersionCommentUpdated(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_VERSION_COMMENT_UPDATED';
        //
    }
    return LibraryDocumentVersionCommentUpdated;
}());
exports.LibraryDocumentVersionCommentUpdated = LibraryDocumentVersionCommentUpdated;
var LibraryDocumentVersionCommentRemove = /** @class */ (function () {
    function LibraryDocumentVersionCommentRemove(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_VERSION_COMMENT_REMOVE';
        //
    }
    return LibraryDocumentVersionCommentRemove;
}());
exports.LibraryDocumentVersionCommentRemove = LibraryDocumentVersionCommentRemove;
var LibraryDocumentVersionCommentRemoved = /** @class */ (function () {
    function LibraryDocumentVersionCommentRemoved(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_DOCUMENT_VERSION_COMMENT_REMOVED';
        //
    }
    return LibraryDocumentVersionCommentRemoved;
}());
exports.LibraryDocumentVersionCommentRemoved = LibraryDocumentVersionCommentRemoved;
function library(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'LIBRARY_LOADED':
            return action.payload;
        case 'LIBRARY_CREATED':
            return state.concat([action.payload]);
        case 'LIBRARY_UPDATED':
            return array_1.not(state, { id: action.payload.id }).concat([action.payload]);
        case 'LIBRARY_REMOVED':
            return array_1.not(state, { id: action.payload.id });
        case 'LIBRARY_DOCUMENT_UPDATED':
            return array_1.not(state, { id: action.payload.id }).concat([action.payload]);
        case 'LIBRARY_DOCUMENT_VERSION_COMMENT_CREATED':
            return general_1.tap(array_1.firstOrFail(array_1.where(state, { id: action.payload.document.id })), function (document) { return array_1.not(state, { id: action.payload.document.id }).concat([
                __assign({}, document, { versions: general_1.tap(array_1.firstOrFail(array_1.where(document.versions, { id: action.payload.version.id })), function (version) { return array_1.not(document.versions, { id: action.payload.version.id }).concat([
                        __assign({}, version, { comments: version.comments.concat([action.payload.comment]) }),
                    ]); }) }),
            ]); });
        case 'LIBRARY_DOCUMENT_VERSION_COMMENT_UPDATED':
            return general_1.tap(array_1.firstOrFail(array_1.where(state, { id: action.payload.document.id })), function (document) { return array_1.not(state, { id: action.payload.document.id }).concat([
                __assign({}, document, { versions: general_1.tap(array_1.firstOrFail(array_1.where(document.versions, { id: action.payload.version.id })), function (version) { return array_1.not(document.versions, { id: action.payload.version.id }).concat([
                        __assign({}, version, { comments: array_1.not(version.comments, { id: action.payload.comment.id }).concat([
                                action.payload.comment,
                            ]) }),
                    ]); }) }),
            ]); });
        case 'LIBRARY_DOCUMENT_VERSION_COMMENT_REMOVED':
            return general_1.tap(array_1.firstOrFail(array_1.where(state, { id: action.payload.document.id })), function (document) { return array_1.not(state, { id: action.payload.document.id }).concat([
                __assign({}, document, { versions: general_1.tap(array_1.firstOrFail(array_1.where(document.versions, { id: action.payload.version.id })), function (version) { return array_1.not(document.versions, { id: action.payload.version.id }).concat([
                        __assign({}, version, { comments: array_1.not(version.comments, {
                                id: action.payload.comment.id,
                            }) }),
                    ]); }) }),
            ]); });
        default:
            return state;
    }
}
exports.library = library;
var LibraryCategoriesLoad = /** @class */ (function () {
    function LibraryCategoriesLoad() {
        this.type = 'LIBRARY_CATEGORIES_LOAD';
    }
    return LibraryCategoriesLoad;
}());
exports.LibraryCategoriesLoad = LibraryCategoriesLoad;
var LibraryCategoriesLoaded = /** @class */ (function () {
    function LibraryCategoriesLoaded(payload) {
        this.payload = payload;
        this.type = 'LIBRARY_CATEGORIES_LOADED';
        //
    }
    return LibraryCategoriesLoaded;
}());
exports.LibraryCategoriesLoaded = LibraryCategoriesLoaded;
function libraryCategories(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case 'LIBRARY_CATEGORIES_LOADED':
            return action.payload;
        default:
            return state;
    }
}
exports.libraryCategories = libraryCategories;
