"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var url_1 = require("@utils/url");
var CustomUrlSerializer = /** @class */ (function (_super) {
    __extends(CustomUrlSerializer, _super);
    function CustomUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomUrlSerializer.prototype.serialize = function (tree) {
        return url_1.pretty(_super.prototype.serialize.call(this, tree));
    };
    return CustomUrlSerializer;
}(router_1.DefaultUrlSerializer));
exports.CustomUrlSerializer = CustomUrlSerializer;
