"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var qs = require("qs");
var config_1 = require("@utils/config");
var auth_1 = require("@utils/auth");
function make(path, params) {
    if (params === void 0) { params = {}; }
    var query = queryString(params);
    if (!query)
        return path;
    return encodeURI("" + path + (path.includes('?') ? '&' : '?') + query);
}
exports.make = make;
function api(url) {
    return url.startsWith('http') ? url : config_1.config('app_url') + '/api' + url;
}
exports.api = api;
function preview(url) {
    window.open(sign(url));
}
exports.preview = preview;
function download(url) {
    window.open(sign(url));
}
exports.download = download;
function open(url) {
    location.href = url.includes('/#/') ? url : sign(url);
}
exports.open = open;
function sign(url) {
    return make(url, { token: auth_1.getToken() });
}
exports.sign = sign;
function queryString(params) {
    return qs.stringify(params, {
        arrayFormat: 'brackets',
        encoder: encodeValue,
    });
}
exports.queryString = queryString;
function pretty(url) {
    return url.replace(/%5B/g, '[').replace(/%5D/g, ']');
}
exports.pretty = pretty;
function encodeValues(input) {
    return Object.entries(input)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return [key, encodeValue(value)];
    })
        .reduce(function (xs, _a) {
        var _b;
        var k = _a[0], v = _a[1];
        return (__assign({}, xs, (_b = {}, _b[k] = v, _b)));
    }, {});
}
exports.encodeValues = encodeValues;
function encodeValue(value) {
    if (typeof value === 'boolean') {
        return value ? '1' : '0';
    }
    return value;
}
exports.encodeValue = encodeValue;
