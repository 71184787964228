"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Upload_component_1 = require("./Upload.component");
var UploadBrowseComponent = /** @class */ (function () {
    function UploadBrowseComponent(upload) {
        this.upload = upload;
        //
    }
    return UploadBrowseComponent;
}());
exports.UploadBrowseComponent = UploadBrowseComponent;
