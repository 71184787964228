"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var url_1 = require("@utils/url");
var TagFormComponent = /** @class */ (function () {
    function TagFormComponent() {
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
    }
    Object.defineProperty(TagFormComponent.prototype, "url", {
        get: function () {
            return url_1.api("/tags/" + this.tag.id);
        },
        enumerable: true,
        configurable: true
    });
    return TagFormComponent;
}());
exports.TagFormComponent = TagFormComponent;
