"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var routes_1 = require("@utils/routes");
var filters_reducer_1 = require("./filters.reducer");
var rx_1 = require("@utils/rx");
var FiltersEffects = /** @class */ (function () {
    function FiltersEffects(actions$, http) {
        var _this = this;
        this.actions$ = actions$;
        this.http = http;
        this.loadFilters$ = this.actions$.pipe(rx_1.ofType('FILTERS_LOAD'), operators_1.mergeMap(function (_a) {
            var workflow = _a.payload.workflow;
            return _this.http.get(routes_1.PROJECT_FILTERS_URL(), { params: { workflow: workflow } });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new filters_reducer_1.FiltersLoaded(payload);
        }));
        //
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], FiltersEffects.prototype, "loadFilters$", void 0);
    return FiltersEffects;
}());
exports.FiltersEffects = FiltersEffects;
