"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./polyfills");
var core_1 = require("@angular/core");
var config_1 = require("@utils/config");
var __NgCli_bootstrap_1 = require("./app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
/**
 * Load all SVG icons into a sprite sheet that will be injected into the page.
 */
var files = require.context('!svg-sprite-loader!../icons', false, /.*\.svg$/);
files.keys().forEach(files);
/**
 * Angular optimizations.
 */
if (process.env.NODE_ENV === 'production') {
    core_1.enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
/**
 * Prevent accidental file drops on the window.
 */
window.addEventListener('drop', function (e) { return e.preventDefault(); }, false);
window.addEventListener('dragover', function (e) { return e.preventDefault(); }, false);
/**
 * Update 'app-release' so we can avoid having
 * to manually click "Reload Application" popup.
 */
localStorage.setItem('app-release', config_1.config('app_release'));
