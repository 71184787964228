"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var auth_1 = require("@utils/auth");
var Checksum_service_1 = require("./Checksum.service");
var url_1 = require("@utils/url");
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(checksum) {
        this.checksum = checksum;
        //
    }
    AuthInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var headers = request.headers
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
        if (auth_1.getToken()) {
            headers = headers.set('Authorization', 'Bearer ' + auth_1.getToken());
        }
        return next
            .handle(request.clone({
            headers: headers,
            url: url_1.pretty(request.urlWithParams),
            params: new http_1.HttpParams(),
        }))
            .pipe(operators_1.tap(function (event) {
            if (event instanceof http_1.HttpResponse) {
                _this.checksum.handle(event);
            }
        }));
    };
    return AuthInterceptor;
}());
exports.AuthInterceptor = AuthInterceptor;
