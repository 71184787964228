"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
function empty() {
    return {
        data: [],
        phrase: '',
        sorting: {
            by: '',
            direction: 'asc',
        },
        pagination: {
            totalItems: 0,
            itemsPerPage: 0,
            currentPage: 0,
        },
    };
}
exports.empty = empty;
function pagination() {
    return function (input$) {
        return input$.pipe(operators_1.map(function (x) { return ({
            data: x.data,
            phrase: x.meta.phrase,
            sorting: {
                by: x.meta.sort_by,
                direction: x.meta.sort_direction,
            },
            pagination: {
                totalItems: x.meta.total,
                itemsPerPage: x.meta.per_page,
                currentPage: x.meta.current_page,
            },
        }); }));
    };
}
exports.pagination = pagination;
