"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var TabsComponent = /** @class */ (function () {
    function TabsComponent(route) {
        this.route = route;
        this.tabs = [];
        this.size = 'sm';
        this.key = 'tab';
        this.select = new core_1.EventEmitter();
        //
    }
    TabsComponent.prototype.activate = function (tab) {
        this.active = tab;
        this.select.emit(tab.key);
    };
    TabsComponent.prototype.add = function (tab) {
        this.tabs.push(tab);
        /**
         * When the first tab is added, we need to automatically activate it.
         * However, activating it immediately would be 'too soon', because
         * the tab's @Input properties would not have been populated yet.
         */
        if (this.route.snapshot.queryParams[this.key] == tab.key || this.tabs.length == 1) {
            this.activate(tab);
        }
    };
    TabsComponent.prototype.queryParams = function (tab) {
        var _a;
        return _a = {}, _a[this.key] = tab.key, _a;
    };
    return TabsComponent;
}());
exports.TabsComponent = TabsComponent;
