"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var operators_1 = require("rxjs/operators");
var ErrorBag_1 = require("@utils/ErrorBag");
var rx_1 = require("@utils/rx");
var array_1 = require("@utils/array");
var CustomProjectFieldComponent = /** @class */ (function () {
    function CustomProjectFieldComponent(store) {
        var _this = this;
        this.store = store;
        this.update = new core_1.EventEmitter();
        this.field$ = this.store.pipe(rx_1.choose(function (x) { return x.template; }), operators_1.mergeMap(function (templates) { return templates; }), operators_1.filter(function (field) { return field.name == _this.name; }), operators_1.take(1));
        //
    }
    CustomProjectFieldComponent.prototype.updated = function (field, value) {
        this.update.emit(value);
        if (field.type == 'SELECT' || field.type == 'MULTI_SELECT') {
            var option = array_1.first(array_1.where(field.values, { value: value }));
            if (!option) {
                return;
            }
            this.project.fields_human[field.name] = option.label;
        }
        else {
            this.project.fields_human[field.name] = value;
        }
    };
    return CustomProjectFieldComponent;
}());
exports.CustomProjectFieldComponent = CustomProjectFieldComponent;
