"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Response_1 = require("@utils/Response");
var array_1 = require("@utils/array");
var UsersLoad = /** @class */ (function () {
    function UsersLoad(payload) {
        this.payload = payload;
        this.type = 'USERS_LOAD';
        //
    }
    return UsersLoad;
}());
exports.UsersLoad = UsersLoad;
var UsersLoaded = /** @class */ (function () {
    function UsersLoaded(payload) {
        this.payload = payload;
        this.type = 'USERS_LOADED';
        //
    }
    return UsersLoaded;
}());
exports.UsersLoaded = UsersLoaded;
var UsersCreated = /** @class */ (function () {
    function UsersCreated(payload) {
        this.payload = payload;
        this.type = 'USERS_CREATED';
        //
    }
    return UsersCreated;
}());
exports.UsersCreated = UsersCreated;
var UsersCreatedFromNewUser = /** @class */ (function () {
    function UsersCreatedFromNewUser(payload) {
        this.payload = payload;
        this.type = 'USERS_CREATED_FROM_NEW_USER';
        //
    }
    return UsersCreatedFromNewUser;
}());
exports.UsersCreatedFromNewUser = UsersCreatedFromNewUser;
var UsersUpdated = /** @class */ (function () {
    function UsersUpdated(payload) {
        this.payload = payload;
        this.type = 'USERS_UPDATED';
        //
    }
    return UsersUpdated;
}());
exports.UsersUpdated = UsersUpdated;
var UsersUpdateState = /** @class */ (function () {
    function UsersUpdateState(payload) {
        this.payload = payload;
        this.type = 'USERS_UPDATE_STATE';
        //
    }
    return UsersUpdateState;
}());
exports.UsersUpdateState = UsersUpdateState;
function users(state, action) {
    if (state === void 0) { state = Response_1.empty(); }
    switch (action.type) {
        case 'USERS_LOADED':
            return action.payload;
        case 'USERS_CREATED':
        case 'USERS_CREATED_FROM_NEW_USER':
            return __assign({}, state, { data: state.data.concat([action.payload]) });
        case 'USERS_UPDATED':
            return __assign({}, state, { data: array_1.not(state.data, { id: action.payload.id }).concat([action.payload]) });
        default:
            return state;
    }
}
exports.users = users;
