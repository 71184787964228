"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var DebugInterceptor = /** @class */ (function () {
    function DebugInterceptor() {
    }
    DebugInterceptor.prototype.intercept = function (request, next) {
        if (!process.env.APP_DEBUG) {
            return next.handle(request);
        }
        return next.handle(request).pipe(operators_1.catchError(function (e) {
            /**
             * Rendering exceptions.
             */
            if (e.status === 500 && typeof e.error.html === 'string') {
                render(e.error.html);
            }
            /**
             * Rendering `dd()` calls
             */
            if (e.status === 200 && (e.error.text || '').includes('window.Sfdump')) {
                render(e.error.text);
            }
            return rxjs_1.throwError(e);
        }));
    };
    return DebugInterceptor;
}());
exports.DebugInterceptor = DebugInterceptor;
function render(html) {
    var doc = document.open('text/html', 'replace');
    doc.write(html);
    doc.close();
}
