"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var url_1 = require("@utils/url");
var UserAvatarComponent = /** @class */ (function () {
    function UserAvatarComponent() {
    }
    Object.defineProperty(UserAvatarComponent.prototype, "style", {
        get: function () {
            return {
                'padding-top': '100%',
                'background-image': "url(" + url_1.sign(this.user.avatar_url) + ")",
            };
        },
        enumerable: true,
        configurable: true
    });
    return UserAvatarComponent;
}());
exports.UserAvatarComponent = UserAvatarComponent;
