"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var operators_1 = require("rxjs/operators");
var routes_1 = require("@utils/routes");
var http_1 = require("@angular/common/http");
var Response_1 = require("@utils/Response");
var general_1 = require("@utils/general");
var users_reducer_1 = require("./users.reducer");
var rx_1 = require("@utils/rx");
var UsersEffects = /** @class */ (function () {
    function UsersEffects(actions$, http) {
        var _this = this;
        this.actions$ = actions$;
        this.http = http;
        this.loadUsers$ = this.actions$.pipe(rx_1.ofType('USERS_LOAD'), operators_1.map(function (_a) {
            var _b = _a.payload, payload = _b === void 0 ? {} : _b;
            return general_1.dropFalsy(payload);
        }), operators_1.switchMap(function (params) { return _this.http.get(routes_1.USERS_URL(), { params: params }); }), Response_1.pagination(), operators_1.map(function (payload) { return new users_reducer_1.UsersLoaded(payload); }));
        this.updateUserState$ = this.actions$.pipe(rx_1.ofType('USERS_UPDATE_STATE'), operators_1.mergeMap(function (_a) {
            var payload = _a.payload;
            return _this.http.put(payload.user.state_url, { state: payload.state });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new users_reducer_1.UsersUpdated(payload);
        }));
        //
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], UsersEffects.prototype, "loadUsers$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], UsersEffects.prototype, "updateUserState$", void 0);
    return UsersEffects;
}());
exports.UsersEffects = UsersEffects;
