"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var operators_1 = require("rxjs/operators");
var routes_1 = require("@utils/routes");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var rxjs_1 = require("rxjs");
var http_1 = require("@angular/common/http");
var array_1 = require("@utils/array");
var library_reducer_1 = require("./library.reducer");
var LibraryEffects = /** @class */ (function () {
    function LibraryEffects(actions$, store, http) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.http = http;
        this.loadLibrary$ = this.actions$.pipe(rx_1.ofType('LIBRARY_LOAD'), operators_1.mergeMap(function () { return _this.http.get(routes_1.LIBRARY_URL()); }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new library_reducer_1.LibraryLoaded(payload);
        }));
        this.loadLibraryCategories$ = this.actions$.pipe(rx_1.ofType('LIBRARY_CATEGORIES_LOAD'), operators_1.mergeMap(function () { return _this.store.pipe(rx_1.once(function (x) { return x.libraryCategories; })); }), operators_1.mergeMap(function (cats) {
            return array_1.notEmpty(cats)
                ? rxjs_1.of(cats)
                : _this.http
                    .get(routes_1.LIBRARY_CATEGORIES_URL())
                    .pipe(operators_1.map(function (x) { return x.data; }));
        }), operators_1.map(function (payload) { return new library_reducer_1.LibraryCategoriesLoaded(payload); }));
        this.documentRemove$ = this.actions$.pipe(rx_1.ofType('LIBRARY_REMOVE'), operators_1.delayWhen(function (_a) {
            var document = _a.payload;
            return _this.http.delete(document.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new library_reducer_1.LibraryRemoved(payload);
        }));
        this.documentVersionCommentCreate$ = this.actions$.pipe(rx_1.ofType('LIBRARY_DOCUMENT_VERSION_COMMENT_CREATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, document = _b.document, version = _b.version, payload = _b.comment;
            return _this.http
                .post(version.comments_url, payload)
                .pipe(operators_1.map(function (_a) {
                var comment = _a.data;
                return ({ document: document, version: version, comment: comment });
            }));
        }), operators_1.map(function (payload) { return new library_reducer_1.LibraryDocumentVersionCommentCreated(payload); }));
        this.documentVersionCommentUpdate$ = this.actions$.pipe(rx_1.ofType('LIBRARY_DOCUMENT_VERSION_COMMENT_UPDATE'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, document = _b.document, version = _b.version, payload = _b.comment;
            return _this.http
                .put(payload.url, payload)
                .pipe(operators_1.map(function (_a) {
                var comment = _a.data;
                return ({ document: document, version: version, comment: comment });
            }));
        }), operators_1.map(function (payload) { return new library_reducer_1.LibraryDocumentVersionCommentUpdated(payload); }));
        this.documentVersionCommentRemove$ = this.actions$.pipe(rx_1.ofType('LIBRARY_DOCUMENT_VERSION_COMMENT_REMOVE'), operators_1.delayWhen(function (_a) {
            var comment = _a.payload.comment;
            return _this.http.delete(comment.url);
        }), operators_1.map(function (_a) {
            var payload = _a.payload;
            return new library_reducer_1.LibraryDocumentVersionCommentRemoved(payload);
        }));
        //
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], LibraryEffects.prototype, "loadLibrary$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], LibraryEffects.prototype, "loadLibraryCategories$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], LibraryEffects.prototype, "documentRemove$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], LibraryEffects.prototype, "documentVersionCommentCreate$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], LibraryEffects.prototype, "documentVersionCommentUpdate$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], LibraryEffects.prototype, "documentVersionCommentRemove$", void 0);
    return LibraryEffects;
}());
exports.LibraryEffects = LibraryEffects;
