"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var url_1 = require("@utils/url");
exports.USERS_URL = function () { return url_1.api('/users'); };
exports.SUPPLIERS_URL = function () { return url_1.api('/suppliers'); };
exports.LIBRARY_URL = function () { return url_1.api('/library'); };
exports.LIBRARY_CATEGORIES_URL = function () { return url_1.api('/library/categories'); };
exports.PROJECTS_URL = function () { return url_1.api('/projects'); };
exports.PROJECTS_FIND_URL = function (id) { return url_1.api(exports.PROJECTS_URL() + '/' + id); };
exports.PROJECTS_NEW_URL = function (id) { return url_1.api(exports.PROJECTS_URL() + "/new" + (id ? '/' + id : '')); };
exports.PROJECT_FILTERS_URL = function () { return url_1.api('/project-filters'); };
exports.PROJECT_FILTER_TEMPLATES_URL = function () { return url_1.api('/project-filter-templates'); };
exports.AUDITS_URL = function () { return url_1.api('/audits'); };
exports.CALENDAR_URL = function () { return url_1.api('/calendar'); };
exports.PROJECT_EXPORTS_URL = function () { return url_1.api('/projects/exports'); };
exports.NOTIFICATIONS_URL = function () { return url_1.api('/notifications'); };
exports.BROADCASTING_AUTH_URL = function () { return url_1.api('/broadcasting/auth'); };
exports.AUTH_PROFILE_URL = function () { return url_1.api('/auth/profile'); };
exports.AUTH_PASSWORD_URL = function () { return url_1.api('/auth/password'); };
exports.AUTH_LOGIN_URL = function () { return url_1.api('/auth/login'); };
exports.AUTH_LOGOUT_URL = function () { return url_1.api('/auth/logout'); };
exports.AUTH_PASSWORD_FORGOTTEN_URL = function () { return url_1.api('/auth/password/forgotten'); };
exports.AUTH_PASSWORD_RESET_URL = function () { return url_1.api('/auth/password/reset'); };
exports.REPORTS_URL = function () { return url_1.api('/reports'); };
exports.CONFIGURATION_URL = function () { return url_1.api('/configuration'); };
